<!-- 二级类目管理 -->
<template>
  <a-button style="margin-bottom: 10px" type="primary" @click="newCreate()">新建二级类目</a-button>
  <a-table bordered rowKey="logicId" size="middle" :columns="columns" :data-source="source" :pagination="pagination"
    :loading="pagination.loading">
    <template #operation="{ record }">
      <a-button type="primary" size="small" @click="editCates(record)">修改</a-button>
    </template>
  </a-table>
  <a-modal :title="modifyModal.title" v-model:visible="modifyModal.visible" :confirm-loading="modifyModal.loading"
    @ok="handleOk" @cancel="handlerCancel">
    <a-spin v-if="modifyModal.status" />
    <a-form v-else ref="formRef" :model="modifyModal.data" :rules="modifyModal.rules" :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }">
      <a-form-item label="类目名称" name="name">
        <a-input v-model:value="modifyModal.data.name" />
      </a-form-item>
      <a-form-item label="上级类目" name="levelOneId">
        <a-select v-model:value="modifyModal.data.levelOneId" style="width: 120px" placeholder="请选择"
          @change="handleParentChange($event)">
          <a-select-option v-for="(item, i) in modifyModal.selects" :key="i" :value="item.logicId">{{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- <a-form-item label="规格单位" name="normsUnit">
        <a-input v-model:value="modifyModal.data.normsUnit" />
      </a-form-item>
      <a-form-item label="规格系数" name="normsNumber">
        <a-input v-model:value="modifyModal.data.normsNumber" type="number" />
      </a-form-item>-->
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, toRaw } from "vue";
import { saveOrUpdate, page, detail } from "@/api/category";
import { getStarList } from "@/api/planet";
import { message } from "ant-design-vue";
export default {
  setup() {
    const {
      formRef,
      columns,
      pagination,
      source,
      modifyModal,
      editCates,
      handlerCancel,
      handleOk,
      newCreate,
      loadingNew,
      handleParentChange
    } = useCategory2Manage();
    return {
      formRef,
      columns,
      pagination,
      source,
      modifyModal,
      editCates,
      handlerCancel,
      handleOk,
      newCreate,
      loadingNew,
      handleParentChange
    };
  }
};
function useCategory2Manage() {
  // 表格标题配置
  const columns = [
    { title: "ID", dataIndex: "logicId" },
    { title: "标题", dataIndex: "name" },
    {
      title: "操作",
      dataIndex: "operation",
      slots: { customRender: "operation" }
    }
  ];
  const rulesMod = {
    // 修改规则
    name: { required: true, message: "请输入类目名称", trigger: "blur" },
    levelOneId: { required: true, message: "请选择上级类目", trigger: "blur" }
  };
  const source = ref([]); // 显示的表格数据
  const formRef = ref();
  // ， ，
  /**
   * title: modal标题
   * visible: modal是否出现
   * loading：modal点击提交按钮是否加载
   * status：modal出现之后是否加载
   * form: 表单数据对象
   * data：网络请求的数据
   * rules: 表单提交轨辙
   * selects: 选择数据
   */
  const modifyModal = ref({
    title: "",
    visible: false,
    loading: false,
    status: true,
    form: {},
    data: {},
    rules: rulesMod,
    selects: []
  });

  const pagination = ref({
    // 分页配置
    loading: true,
    position: "bottom",
    total: 0, // 条数
    defaultPageSize: 20, // 默认分页条数
    pageSizeOptions: ["5", "10", "15", "20", "30"],
    showSizeChanger: true, // 是否可以改变 pageSize
    showQuickJumper: true, // 是否可以快速跳转至某页
    showTotal: total => `共 ${total} 条`,
    hideOnSinglePage: false, // 只有一页时是否隐藏分页器
    onChange: (selectedRowKeys, selectedRows) => {
      console.log(selectedRowKeys, selectedRows);
      pagination.value.loading = true;
      getList(selectedRowKeys, selectedRows);
    }
  });
  const handleParentChange = e => {
    modifyModal.value.data.levelOneId = e;
  };
  // 加载修改页面
  const loadingMod = async logicId => {
    const { data, status } = await detail({ id: logicId });
    const { data: levelOneList, status: levelOneStatus } = await page({
      pageNo: 1,
      level: "1"
    });
    if (status == "200" && levelOneStatus == "200") {
      const { name, starId, logicId, parentId, normsUnit, normsNumber } = data;
      modifyModal.value.data = {
        ...modifyModal.value.data,
        name,
        starId,
        logicId,
        parentId,
        normsUnit,
        levelOneId: parentId,
        normsNumber
      }; // 将要修改的数据加载到modal
      // modifyModal.value.data.levelOneId = levelOneList.dataOnThisPage.filter(
      //   e => e.logicId === parentId
      // )[0];
      modifyModal.value.status = false; //停止modal加载状态
      modifyModal.value.selects = levelOneList.dataOnThisPage;
      return;
    }
    message.warning("系统繁忙");
  };
  // 加载创建页面
  const loadingNew = async () => {
    const { data: levelOneList, status: levelOneStatus } = await page({
      pageNo: 1,
      level: "1"
    });
    if (levelOneStatus == "200") {
      modifyModal.value.status = false; //停止modal加载状态
      modifyModal.value.selects = levelOneList.dataOnThisPage;
      return;
    }
    message.warning("系统繁忙");
  };
  // 触发修改页面
  const editCates = row => {
    modifyModal.value.title = "修改二级类目";
    modifyModal.value.visible = true; // 打开modal
    modifyModal.value.status = true;
    const { logicId } = row;
    loadingMod(logicId);
  };
  // 触发新建页面
  const newCreate = () => {
    modifyModal.value.title = "新建二级类目";
    modifyModal.value.visible = true; // 打开modal
    modifyModal.value.data = {}; // 初始化modal重的表格数据
    modifyModal.value.status = false; // 停止modal加载状态
    modifyModal.value.loading = false; // 提交按钮加载状态
    loadingNew();
  };

  const handlerCancel = async () => {
    modifyModal.value.status = true;
    modifyModal.value.loading = false; // 提交按钮加载状态
  };
  const handleOk = () => {
    formRef.value
      .validate()
      .then(() => {
        const exce = async () => {
          modifyModal.value.loading = true;
          const {
            name,
            starId,
            logicId,
            levelOneId,
            normsUnit,
            normsNumber
          } = modifyModal.value.data;
          const { status, msg } = await saveOrUpdate({
            logicId,
            level: "2",
            name,
            parentId: levelOneId,
            normsExplain: "",
            needModel: "0",
            normsUnit,
            normsNumber
          });
          if (status == "200") {
            message.success(msg);
            modifyModal.value.loading = false;
            modifyModal.value.visible = false;
            getList();
            return;
          }
          modifyModal.value.loading = false;
          message.error(msg);
        };
        exce();
      })
      .catch(error => {
        console.log("error", error);
      });
  };
  // 获取分页数据
  async function getList(pageNo = "1", pageSize = "20", level = "2") {
    const { data, status } = await page({ pageNo, pageSize, level });
    if (status == "200") {
      const { total, dataOnThisPage } = data;
      pagination.value.total = total || 0;
      source.value = dataOnThisPage || [];
    }
    pagination.value.loading = false;
  }
  onMounted(getList);
  // onMounted(async () => {
  //   const { data } = await getStarList({ pageNum: 1, pageSize: 100 });
  //   const { dataOnThisPage } = data;
  //   modifyModal.value.selects = dataOnThisPage || [];
  // });
  return {
    formRef,
    columns,
    pagination,
    source,
    modifyModal,
    editCates,
    handlerCancel,
    handleOk,
    newCreate,
    loadingNew,
    handleParentChange
  };
}
</script>

<style>
</style>
