import { requestInsTwo, requestIns } from '@/utils/axios'
import { stringify } from 'qs'

// 分页请求星球列表
export function getStarList (params) {
  return requestInsTwo({
    url: `/star/list?${stringify(params)}`,
    method: 'get'
  })
}

// 增加/修改星球
export function addAndUpdateStar (params) {
  return requestIns({
    url: `/admin/star/saveOrUpdate?${stringify(params)}`,
    method: 'post'
  })
}

// 获取文件token
export function uploadFile (name) {
  return requestIns({
    url: `file/token?suffixName=${name}`,
    method: 'get'
  })
}

// 分页查询品牌
export function getBrandList (params) {
  return requestIns({
    url: `/admin/goods/goodsBrands/list?${stringify(params)}`,
    method: 'get'
  })
}

// 增加/修改商品品牌
export function addAndUpdateBrand (params) {
  return requestIns({
    url: `/admin/goods/goodsBrands/saveOrUpdate?${stringify(params)}`,
    method: 'post'
  })
}

// 分页查询分类
export function getCategoryList (params) {
  return requestIns({
    url: `/admin/goods/goodsCategory/list?${stringify(params)}`,
    method: 'get'
  })
}

// 增加或修改商品费雷
export function addAndUpdateCategory (params) {
  return requestIns({
    url: `/admin/goods/goodsCategory/saveOrUpdate?${stringify(params)}`,
    method: 'post'
  })
}
